(function DerivMarketingCookies() {
  // Initialize logging array in window
  window.marketingCookieLogs = [];

  const log = (action, details) => {
    window.marketingCookieLogs.push({
      timestamp: new Date().toISOString(),
      action,
      details,
    });
  };

  log("DerivMarketingCookies", "Initialization started");

  /* utility functions */
  const getDomain = () => {
    const host_domain = location.hostname;
    const allowed_domains = ["deriv.com", "binary.sx"];

    const matched_domain = allowed_domains.find((allowed_domain) =>
      host_domain.includes(allowed_domain)
    );

    return matched_domain ?? host_domain;
  };

  const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; expires=Tue, 19 Jan 9999 03:14:07 UTC; domain=${getDomain()}; path=/; SameSite=None; Secure;`;
    log("setCookie", {
      name,
      value,
      domain: getDomain(),
    });
  };

  const eraseCookie = (name) => {
    document.cookie = `${name}=; Max-Age=-99999999; domain=${getDomain()}; path=/;`;
    log("eraseCookie", { name });
  };

  const getCookie = (name) => {
    const dc = document.cookie;
    const prefix = name + "=";

    // check begin index
    let begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      // cookie not available
      if (begin != 0) return null;
    } else {
      begin += 2;
    }

    // check end index
    let end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
  };

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const toISOFormat = (date) => {
    if (date instanceof Date) {
      const utc_year = date.getUTCFullYear();
      const utc_month =
        (date.getUTCMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
      const utc_date = (date.getUTCDate() < 10 ? "0" : "") + date.getUTCDate();

      return `${utc_year}-${utc_month}-${utc_date}`;
    }

    return "";
  };

  const shouldOverwrite = (new_utm_data, current_utm_data) => {
    // If we don't have old utm data, the utm_source field is enough for new utm data
    const valid_new_utm_source =
      new_utm_data.utm_source && new_utm_data.utm_source !== "null";
    if (!current_utm_data && valid_new_utm_source) {
      log('shouldOverwrite', {
        reason: 'No current UTM data and valid new UTM source',
        new_utm_data
      });
      return true;
    }

    // If we have old utm data, 3 fields are required for new utm data to rewrite the old one
    const required_fields = ["utm_source", "utm_medium", "utm_campaign"];
    const has_new_required_fields = required_fields.every(
      (field) => new_utm_data[field]
    );
    if (has_new_required_fields) {
      log('shouldOverwrite', {
        reason: 'All required fields present in new UTM data',
        new_utm_data,
        current_utm_data
      });
      return true;
    }

    log('shouldOverwrite', {
      reason: 'Conditions not met for overwrite',
      new_utm_data,
      current_utm_data
    });
    return false;
  };

  const searchParams = new URLSearchParams(window.location.search);
  const brand_name = "deriv";
  const app_id = 11780;

  /* start handling UTMs */
  const utm_fields = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "utm_ad_id",
    "utm_click_id",
    "utm_adgroup_id",
    "utm_campaign_id",
    "utm_msclk_id",
    // For cases where we need to map the query param to some different name e.g [name_from_query_param, mapped_name]
    ["fbclid", "utm_fbcl_id"],
  ];

  let utm_data = {};
  const current_utm_data = JSON.parse(
    decodeURIComponent(getCookie("utm_data"))
  );

  // If the user comes to the site for the first time without any URL params
  // Only set the utm_source to referrer if the user does not have utm_data cookies stored
  if (!current_utm_data?.utm_source) {
    utm_data = {
      utm_source: document.referrer ? document.referrer : "null",
    };
  }

  // If the user has any new UTM params, store them
  utm_fields.forEach((field) => {
    if (Array.isArray(field)) {
      const [field_key, mapped_field_value] = field;
      if (searchParams.has(field_key)) {
        utm_data[mapped_field_value] = searchParams
          .get(field_key)
          .substring(0, 200); // Limit to 200 supported characters
      }
    } else {
      if (searchParams.has(field)) {
        utm_data[field] = searchParams.get(field).substring(0, 100); // Limit to 100 supported characters
      }
    }
  });

  if (shouldOverwrite(utm_data, current_utm_data)) {
    eraseCookie("affiliate_tracking");
    eraseCookie("utm_data");

    const utm_data_cookie = encodeURIComponent(JSON.stringify(utm_data))
      .replaceAll("%2C", ",")
      .replaceAll("%7B", "{")
      .replaceAll("%7D", "}");

    setCookie("utm_data", utm_data_cookie);
  }

  /* end handling UTMs */

  /* start handling affiliate tracking */
  const isAffiliateTokenExist =
    searchParams.has("t") || searchParams.has("affiliate_token");
  if (isAffiliateTokenExist) {
    eraseCookie("affiliate_tracking");
    const affiliateToken =
      searchParams.get("t") || searchParams.get("affiliate_token");
    setCookie("affiliate_tracking", affiliateToken);
  }

  if (searchParams.has("sidc")) {
    eraseCookie("affiliate_tracking");
    const sidcValue = searchParams.get("sidc");
    setCookie("affiliate_tracking", sidcValue);
  }
  /* end handling affiliate tracking */

  /* start handling signup device */
  const signup_device_cookie_unparsed = getCookie("signup_device") || "{}";
  const signup_device_cookie = JSON.parse(
    decodeURI(signup_device_cookie_unparsed).replaceAll("%2C", ",")
  );
  if (!signup_device_cookie.signup_device) {
    const signup_data = {
      signup_device: isMobile() ? "mobile" : "desktop",
    };
    const signup_data_cookie = encodeURI(JSON.stringify(signup_data))
      .replaceAll(",", "%2C")
      .replaceAll("%7B", "{")
      .replaceAll("%7D", "}");

    document.cookie = `signup_device=${signup_data_cookie};domain=${getDomain()}; path=/; SameSite=None; Secure;`;
  }
  /* end handling signup device */

  /* start handling date first contact */
  const date_first_contact_cookie_unparsed =
    getCookie("date_first_contact") || "{}";
  const date_first_contact_cookie = JSON.parse(
    decodeURI(date_first_contact_cookie_unparsed).replaceAll("%2C", ",")
  );

  if (!date_first_contact_cookie.date_first_contact) {
    const date_first_contact_response = Math.floor(Date.now() / 1000);

    const date_first_contact_data = {
      date_first_contact: toISOFormat(
        new Date(date_first_contact_response * 1000)
      ),
    };

    const date_first_contact_data_cookie = encodeURI(
      JSON.stringify(date_first_contact_data)
    )
      .replaceAll(",", "%2C")
      .replaceAll("%7B", "{")
      .replaceAll("%7D", "}");

    document.cookie = `date_first_contact=${date_first_contact_data_cookie};domain=${getDomain()}; path=/; SameSite=None; Secure;`;
  }
  /* end handling date first contact */

  /* start handling gclid */
  const gclid = searchParams.get("gclid");
  const gclid_url = searchParams.get("gclid_url");
  const final_gclid = gclid || gclid_url || "";

  if (!!final_gclid) {
    eraseCookie("gclid");
    setCookie("gclid", final_gclid);
  }
  /* end handling gclid */

  /* start handling campaign channel */
  const campaign_channel = searchParams.get("ca");

  if (campaign_channel) {
    eraseCookie("campaign_channel");
    setCookie("campaign_channel", campaign_channel);
  }
  /* end handling campaign channel */

  log("DerivMarketingCookies", "Initialization completed");
})();
